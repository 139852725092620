import React from "react";

import "../Assets/Styles/Contact.css";
import ContactLeft from "../Components/contact/ContactLeft";
import ContactRight from "../Components/contact/ContactRight";

function Contact() {
  return (
    <div className="wrapper">
      <div className="contact">
        <div className="contact__container containers">
          <div className="contact__content">
            <ContactLeft />
            <ContactRight />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
