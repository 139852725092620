import React from "react";
import { IoMailSharp, IoCallSharp, IoLocationSharp } from "react-icons/io5";

function ContactLeft() {
  return (
    <div className="contact__left">
      <div className="contact__left-header">
        <h2>Get in Touch</h2>
        <p>Any questions or enquiries, kindly reach out to us</p>
      </div>
      <div className="contact__left-sects">
        <div className="contact__left-sect">
          <IoMailSharp className="contact__left-icon" />
          <span>enquiry@reworkacademy.co</span>
        </div>
        <div className="contact__left-sect">
          <IoCallSharp className="contact__left-icon" />
          <span>+234-703-561-6891</span>
        </div>
        <div className="contact__left-sect">
          <IoLocationSharp className="contact__left-icon" />
          <span>House C5, Aknaton estate, Gospel light avenue, off dunamis road, Durumi, Area 1, Abuja.</span>
        </div>
      </div>
    </div>
  );
}

export default ContactLeft;
