import { IoCheckmarkCircleOutline, IoCloseSharp } from "react-icons/io5";

export const EventsSuccess = ({ setRegSuccess }) => {
  const handleClose = () => {
    setRegSuccess(false);
    window.location.reload();
  };

  return (
    <div className="successWebContainer">
      <div className="successWeb">
        <div className="wb__success">
          <IoCheckmarkCircleOutline className="wb__success-icon" />
        </div>
        <h2>Registration Successful.</h2>
        <h4>Thank you for registering for the event</h4>
        <p>You will receive an email with details on attending the event. We look forward to seeing you there!</p>
        <button className="wb__close">
          <IoCloseSharp onClick={handleClose} />
        </button>
      </div>
    </div>
  );
};
