import "../Assets/Styles/Smarties.css";
import { EmpowerLeaners } from "../Components/smarties/EmpowerLeaners";
import { GetInTouch } from "../Components/smarties/GetInTouch";
import { OurProgram } from "../Components/smarties/OurProgram";
import { SmartiesBanner } from "../Components/smarties/SmartiesBanner";

function Smarties() {
  return (
    <div className="wrapper">
      <div className="smarties">
        <SmartiesBanner />
        <EmpowerLeaners />
        <OurProgram />
        <GetInTouch />
      </div>
    </div>
  );
}

export default Smarties;
