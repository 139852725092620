import React from "react";
import { Link } from "react-router-dom";
import SubFooter from "../../Layouts/SubFooter";

function JoinRework() {
  return (
    <SubFooter title={"Want to be a trainee at Rework Academy?"}>
      <div className="sub-footer__links">
        <Link to={"/courses"} className="link__btn link__btn-bg">
          Apply Now
        </Link>
      </div>
    </SubFooter>
  );
}

export default JoinRework;
