import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { RiLoader4Fill } from "react-icons/ri";
import { IoCalendarSharp, IoTimeSharp } from "react-icons/io5";

function EventsList() {
  const url = process.env.REACT_APP_URL;
  const [events, setEvents] = useState([]);
  const [eventErr, setEventErr] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getEvents = async () => {
      try {
        const resp = await axios.get(`${url}/webinar?completed=false`);
        if (resp.ok || resp.data.data.length) {
          setEvents((prev) => (prev = resp.data.data));
          setLoading(false);
        } else {
          setEventErr(true);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setEvents([]);
        setEventErr(true);
        setLoading(false);
      }
    };
    getEvents();
  }, [url]);

  return (
    <>
      <div className="webinar__lists">
        {loading === true ? (
          <div className="webinar_loading_icon">
            <RiLoader4Fill className="web_loading_icon" />
          </div>
        ) : (
          events.length !== 0 && (
            <div className="containers">
              <div className="wb__lists_items">
                {events.map((event) => (
                  <Link to={`/events/${event.id}`} className="wb__list" key={event.id}>
                    <img src={event.image} crossOrigin="anonymous" alt="Event" />
                    <div className="wb__list_content">
                      <h3>{event.title}</h3>
                      <p className="wb__list_descp">{event.short_descp}</p>
                      <div className="wb__list_dates">
                        <h4>
                          <IoCalendarSharp /> {event.date}
                        </h4>
                        <h4>
                          <IoTimeSharp /> {event.time} (GMT +1)
                        </h4>
                      </div>
                      <div className="wb__list_link_container">
                        <span className="wb__list-link">Register</span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )
        )}
        {eventErr === true ? <div className="networkErr no_event_err">No Events Available Currently</div> : null}
      </div>
    </>
  );
}

export default EventsList;
