import "../Assets/Styles/Events.css";
import { useEffect, useState } from "react";
import { IoCalendarSharp, IoTimeOutline, IoLocationOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { RiLoader4Fill } from "react-icons/ri";

function EventsDetails() {
  const { id } = useParams();
  const url = process.env.REACT_APP_URL;
  const [loading, setLoading] = useState(true);
  const options = { weekday: "long", year: "numeric", month: "short", day: "numeric" };
  const [webEvent, setWebEvent] = useState({});
  const [eventErr, setEventErr] = useState(false);
  const format_date = (date) => new Date(date).toLocaleDateString("en-us", options);

  useEffect(() => {
    const getEvent = async () => {
      const resp = await axios.get(`${url}/webinar/${id}`);
      try {
        if (resp.data.id) {
          setWebEvent(resp.data);
          setLoading(false);
          setEventErr(false);
        } else {
          setLoading(false);
          setEventErr(true);
        }
      } catch {
        setLoading(false);
        setEventErr(true);
      }
    };
    getEvent();
  }, [id, url]);

  return (
    <div className="wrapper">
      <div className="webinars">
        {loading === true ? (
          <div className="webinar_loading_icon web_details_loading">
            <RiLoader4Fill className="web_loading_icon" />
          </div>
        ) : eventErr === true ? (
          <div className="networkErr no_event_err no_event_details_err">Event Does not Exist</div>
        ) : (
          <div className="eventDetails_container">
            <div className="eventDetails_grid">
              <div className="event_details_1">
                <img src={webEvent.image} crossOrigin="anonymous" alt="Event" />
              </div>
              <div className="event_details_2">
                <div className="event_content_1">
                  <div className="event_c_dts_header">
                    <h2>{webEvent.title}</h2>
                  </div>
                  <div className="event_c__dts">
                    <IoCalendarSharp />
                    <p>{format_date(webEvent.date)}</p>
                  </div>
                  <div className="event_c__dts">
                    <IoTimeOutline />
                    <p>{webEvent.time}</p>
                  </div>
                  <div className="event_c__dts">
                    <IoLocationOutline />
                    <p>{webEvent.webinar_mode}</p>
                  </div>
                </div>
                <div className="event_content_2">
                  <h3>Registration</h3>
                  <p> To join the event, please register below</p>
                  <Link to={`/events/register/${webEvent.id}`}>Register</Link>
                </div>
                <div className="event_content_3">
                  <div className="event_content3_header">
                    <h3>Event Details</h3>
                  </div>
                  <h3 className="event_content3_title">How to break out into the tech world without tech background</h3>
                  <p className="event_content3_alt">
                    <strong>Date: </strong>
                    <span>{format_date(webEvent.date)}</span>, <span>{webEvent.time}</span>
                  </p>
                  <p className="event_content3_alt">
                    <strong>Location: </strong>
                    <span>{webEvent.webinar_mode}</span>
                  </p>
                  <div className="event_content3_descpr">
                    <h4>Details: </h4>
                    <p>{webEvent.short_descp}</p>
                  </div>
                  {webEvent.why_join ? (
                    <div className="event_content3_descpr">
                      <h4>Why Attend?</h4>
                      <p>{webEvent.why_join}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EventsDetails;
