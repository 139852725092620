import React from "react";

function AdmissionProcess() {
  return (
    <div className="admission-process">
      <div className="adm__container containers">
        <div className="adm__section-header">
          <h3>Our Admission & Selection Process</h3>
        </div>
        <div className="adm__section">
          <div className="adm__sect-box">
            <p>Apply for course online</p>
          </div>
          <div className="adm__sect-box">
            <p>Attend class to prepare you for assessment</p>
          </div>
          <div className="adm__sect-box">
            <p>Complete & Pass assessment project</p>
          </div>
          <div className="adm__sect-box">
            <p>Successful Candidates are offered admission</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdmissionProcess;
