export const CourseLoading = () => {
  return (
    <div className="containers">
      <div className="courseHeight">
        <div className="courseHeight-left">
          <div className="courseLodingText1 loadningAnim"></div>
          <div className="courseLodingText2 loadningAnim"></div>
          <div className="courseLodingText3 loadningAnim"></div>
          <div className="courseLodingText4">
            <span className="courseLoadinBtns loadningAnim"></span>
            <span className="courseLoadinBtns loadningAnim"></span>
          </div>
          <div className="courseLodingText6 loadningAnim"></div>
        </div>
        <div className="courseHeight-right"></div>
      </div>
    </div>
  );
};
