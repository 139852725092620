import React from "react";
import "../Assets/Styles/Home.css";
import Banner from "../Components/Home/Banner";
import GetSkills from "../Components/Home/GetSkills";
import GetTrained from "../Components/Home/GetTrained";
import KnowMore from "../Components/Home/KnowMore";
import Testimonials from "../Components/Home/Testimonials";
import WhyChooseUs from "../Components/Home/WhyChooseUs";

function Home() {
  return (
    <div className="wrapper">
      <Banner />
      <WhyChooseUs />
      <GetSkills />
      <Testimonials />
      <GetTrained />
      <KnowMore />
    </div>
  );
}

export default Home;
