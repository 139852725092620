import React from "react";

function BannerCourses({ titleCourse }) {
  return (
    <div className="courses-banner">
      <div className="courses-banner__containers containers">
        <div className="courses-banner__header">
          <h2>{titleCourse}</h2>
          <p>
            Our courses are carefully designed and structured to deliver the best quality <br /> to take you from
            beginner to advanced skill level.
          </p>
        </div>
      </div>
    </div>
  );
}

export default BannerCourses;
