import React, { useContext } from "react";
import BannerCourses from "../Components/courses/BannerCourses";
import CourseCards from "../Components/courses/CourseCards";
import { CourseContext } from "../Contexts/CourseContext";
import "../Assets/Styles/Courses.css";

function AllCourses() {
  const { course } = useContext(CourseContext);
  return (
    <div className="wrapper">
      <div className="courses">
        <BannerCourses titleCourse={"Our Courses"} />
        <CourseCards course={course} />
      </div>
    </div>
  );
}

export default AllCourses;
