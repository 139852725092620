import React from "react";
import { Link } from "react-router-dom";
import SubFooter from "../../Layouts/SubFooter";

function KnowMore() {
  return (
    <SubFooter title={"Want to find out more? We are here for you"}>
      <div className="sub-footer__links">
        <Link to={"/contact-us"} className="link__btn link__btn-bg">
          Contact Us
        </Link>
        <Link to={"/contact-us"} className="link__btn link__btn-outline">
          Hire our graduates
        </Link>
      </div>
    </SubFooter>
  );
}

export default KnowMore;
