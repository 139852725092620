import React from "react";

export const BannerEvents = () => {
  return (
    <div className="courses-banner">
      <div className="courses-banner__containers containers">
        <div className="courses-banner__header">
          <h2>Our Events</h2>
          <p>
            Our series of events are organised to keep you informed and updated on the fast-growing tech industry. <br /> We also introduce you to new
            technologies, don’t miss out on our free trainings too.
          </p>
        </div>
      </div>
    </div>
  );
};
