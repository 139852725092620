import axios from "axios";
import React, { useContext, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { CourseContext } from "../../Contexts/CourseContext";

function ContactRight() {
  const url = process.env.REACT_APP_URL;
  const [err, setErr] = useState(false);
  const { succss, setSuccss } = useContext(CourseContext);
  const initialData = {
    full_name: "",
    email: "",
    phone: "",
    msg: "",
  };

  const [data, setData] = useState(initialData);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const saveFormData = [...Object.values(data)].every(Boolean);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!saveFormData) {
      setErr((prevData) => (prevData = true));
      return;
    }
    const sendMessage = async () => {
      const resp = await axios.post(`${url}/contacts`, data);
      const msg = await resp.data;
      if (!msg) {
        return;
      }
      setSuccss((prevSuccess) => (prevSuccess = true));
      setData(initialData);
      setErr((prev) => (prev = false));
    };
    sendMessage();
  };

  const HandleCloseMsg = () => {
    setSuccss((Sucs) => (Sucs = false));
  };

  return (
    <>
      <div className="contact__right">
        <form action="" className="contact__form" onSubmit={handleFormSubmit}>
          <div className="contact__form-group">
            <label htmlFor="full_name">Name</label>
            <input type="text" name="full_name" value={data.full_name} onChange={handleInput} className={err && data.full_name === "" ? "err-input" : null} />
          </div>
          <div className="contact__form-group">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" value={data.email} onChange={handleInput} className={err && data.email === "" ? "err-input" : null} />
          </div>
          <div className="contact__form-group">
            <label htmlFor="phone">Phone number</label>
            <input type="text" name="phone" value={data.phone} onChange={handleInput} className={err && data.phone === "" ? "err-input" : null} />
          </div>
          <div className="contact__form-group">
            <label htmlFor="msg">Message</label>
            <textarea name="msg" value={data.msg} onChange={handleInput} className={err && data.msg === "" ? "err-input" : null}></textarea>
          </div>
          <div className="contact__form-group">
            <button className="link__btn link__btn-bg-blue">Submit</button>
          </div>
        </form>
      </div>
      {succss === true && (
        <div className="successMsgContainer">
          <div className="successMsgContent">
            <div className="successMsg">
              <div className="successMsg__close" onClick={HandleCloseMsg}>
                <IoCloseSharp className="sucessMsgCloseIcon" />
              </div>
              <div className="successMsg__descrp">
                <h2>Thank you for reaching out to us.</h2>
                <p>A representative will reach out to you shortly.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ContactRight;
