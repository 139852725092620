import React from "react";
import "../Assets/Styles/WhyRework.css";
import AboutUs from "../Components/WhyRework/AboutUs";
import BannerWhyRework from "../Components/WhyRework/BannerWhyRework";
import JoinRework from "../Components/WhyRework/JoinRework";
import OurMission from "../Components/WhyRework/OurMission";
import OurValues from "../Components/WhyRework/OurValues";

function WhyRework() {
  return (
    <div className="wrapper">
      <div className="why-rework">
        <div className="why-rework__container">
          <BannerWhyRework />
          <AboutUs />
          <OurMission />
          <OurValues />
          <JoinRework />
        </div>
      </div>
    </div>
  );
}

export default WhyRework;
