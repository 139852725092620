import React from "react";

function OurValues() {
  return (
    <div className="containers values">
      <div className="values__content v__content-2">
        <h4>Why Rework</h4>
        <p>
          Rework Academy is founded with the aim to give individuals with no tech skill a high-level tech skill to fill the very obvious technical skill shortage gap in
          the fast-rising technology industry. We are developing local talents from zero skill to advanced employable skill. By implementing Empirical Process Control in
          Training, we are developing the best hands for the technology industry. Best of all, with our flexible monthly payment plan, you pay as you learn.
        </p>
        <p>
          At Rework Academy, our core focus is developing skills, making every student an important project to us, that is why we introduced and use the Empirical Process
          Control in our training, this process gives our students the ability to learn at their pace as we continue to observe and redefine the training process uniquely
          for every student.
        </p>
        <p>We believe there are enough hands to fill the gap, if given the right technical skills needed by the industry.</p>
      </div>
    </div>
  );
}

export default OurValues;
