import React from "react";

function AboutUs() {
  return (
    <div className="about">
      <div className="containers about__container">
        <h3>About Us</h3>
        <div>
          <p>
            Rework Academy is a skill development and placement company, with focus on filling the technical skill gap
            in Nigeria and Africa. We are building skills with global standard, adopting curriculum that takes
            individuals with zero skill to employable skilled talent.
          </p>
          <p>
            We deliver the most demanded technical skills by working with professionals who work everyday with the same
            skills. We have adopted curriculum with the most sorted after technical skills in the industry. Our
            curriculum is delivered by professionals with industry experience. The Focus is to get you industry ready
            with skills that get you hired and transform your Career.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
