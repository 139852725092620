import React from "react";
import CourseClassOption from "./CourseClassOption";

function ClassOption({ course }) {
  return (
    <div className="course-options">
      <div className="course-options__container containers">
        <div className="co__section-title">
          <h3>{course.name} Class and Payment Options</h3>
        </div>
        <div className="co__sections">
          {course.physical?.week_day.length ? (
            <CourseClassOption
              course={course}
              option={"(Weekday Option)"}
              location={"Classroom"}
              schedule={course.physical?.week_day.map((sch, i) => (
                <span key={i} className="co__box-icon_span">
                  {`${sch.name}: ${sch.timing} `}
                </span>
              ))}
              fee={course.physical.total_fee}
              monthly_fee={course.physical.monthly_fee}
            />
          ) : null}
          {course.physical?.week_end.length ? (
            <CourseClassOption
              course={course}
              option={"(Weekend Option)"}
              location={"Classroom"}
              schedule={course.physical?.week_end.map((sch, i) => (
                <span key={i} className="co__box-icon_span">
                  {`${sch.name}: ${sch.timing} `}{" "}
                </span>
              ))}
              fee={course.physical.total_fee}
              monthly_fee={course.physical.monthly_fee}
            />
          ) : null}
          {course.virtual?.week_day.length ? (
            <CourseClassOption
              course={course}
              option={"(Remote Option)"}
              location={"Online (Instructor-led)"}
              schedule={course.virtual?.week_day.map((sch, i) => (
                <span key={i} className="co__box-icon_span">
                  {`${sch.name}: ${sch.timing} `}{" "}
                </span>
              ))}
              fee={course.virtual.total_fee}
              monthly_fee={course.virtual.monthly_fee}
            />
          ) : null}
          {course.virtual?.week_end.length ? (
            <CourseClassOption
              course={course}
              option={"(Weekend Option)"}
              location={"Online (Instructor-led)"}
              schedule={course.virtual?.week_end.map((sch, i) => (
                <span key={i} className="co__box-icon_span">
                  {`${sch.name}: ${sch.timing} `}{" "}
                </span>
              ))}
              fee={course.virtual.total_fee}
              monthly_fee={course.virtual.monthly_fee}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ClassOption;
