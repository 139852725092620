import React from "react";
import App from "./App";
import CourseProvider from "./Contexts/CourseContext";

const AppWrapper = () => {
  return (
    <CourseProvider>
      <App />
    </CourseProvider>
  );
};

export default AppWrapper;
