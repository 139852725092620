export const OurProgram = () => {
  return (
    <section className="smarties--section2">
      <div className="smarties--section2__sub">
        <div className="smarties--section2__sub1">
          <h5>OUR PROGRAM</h5>
          <h2>Integrates robust Practical ICT education curriculum to schools, Provide trained ICT Instructors.</h2>
        </div>
        <div className="smarties--section2__sub1">
          <p>
            We bring practical ICT education program to schools; we bring the rework experience to school, which redefines the learning experience and provides high level
            ICT skills by implementing curriculum that favourably compares to curriculums of most developed countries though with a focus on the Nigerian student. This
            program will develop learners and empower them with usable skills by learning through hands-on practical sessions.
          </p>
          <p>
            Our curriculum is specially intended for today’s students, keeping in mind the future before them; it brings learners up to speed with the fast changing
            technologies.
          </p>
          <p>
            The curriculum is carefully put together and comes with the required software, trained ICT Instructors, support services and periodic training for school
            teachers.
          </p>
        </div>
      </div>
    </section>
  );
};
