import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/Images/logo.png";
import "../Assets/Styles/Footer.css";
import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter } from "react-icons/io5";
import { DataContext } from "../Contexts/CourseContext";

function Footer() {
  const date = new Date().getFullYear();
  const { course } = DataContext();
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer-content__img">
          <Link to={"/"}>
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="footer__contents">
          <div className="footer__content footer__content1">
            <div className="footer-content__sub">
              <h3>Address</h3>
              <p>House C5, Aknaton estate, Gospel light avenue, off dunamis road, Durumi, Area 1, Abuja.</p>
            </div>
            <div className="footer-content__sub">
              <h3>Social Links</h3>
              <ul className="footer-content__sub-links">
                <li>
                  <a href="https://web.facebook.com/ReworkAcademy/">
                    <IoLogoFacebook />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/reworkacademy/">
                    <IoLogoInstagram />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/ReworkAcademy">
                    <IoLogoTwitter />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/reworkacademy/">
                    <IoLogoLinkedin />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-content__sub">
              <h3>Contact</h3>
              <a href="tel:+234 703 561 6891" target={"_blank"} rel="noreferrer">
                +234 703 561 6891
              </a>
              <a href="mailto:enquiry@reworkacademy.co">enquiry@reworkacademy.co</a>
            </div>
          </div>
          <div className="footer__content footer__content2">
            <h3>Courses</h3>
            <ul className="footer-content__list">
              {course
                ? course.map((co) => (
                    <li key={co.id}>
                      <Link to={`/courses${co.route}`}>{co.name}</Link>
                    </li>
                  ))
                : null}
            </ul>
          </div>
          <div className="footer__content footer__content3">
            <h3>Company</h3>
            <ul className="footer-content__list">
              <li>
                <Link to="/why-rework-academy">Why Rework Academy</Link>
              </li>
              <li>
                <Link to={"/contact-us"}>Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-sub__contents">
          <p>Copyright &copy; {date} Rework Academy. All rights reserved</p>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
