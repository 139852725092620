import React from "react";
import { Link } from "react-router-dom";
import Person from "../../Assets/Images/Victor-Onoja-PhotoRoom-min.png";
import NodeLogo from "../../Assets/Images/nodejs_logo.png";
import PythonLogo from "../../Assets/Images/python_logo.png";
import BootstrapLogo from "../../Assets/Images/bootstrap2_logo.png";
import mySQLLogo from "../../Assets/Images/mysql_logo.png";
import mongodbLogo from "../../Assets/Images/mongodb.png";
import JavaLogo from "../../Assets/Images/java_logo.png";
import DartLogo from "../../Assets/Images/dart_logo.png";
import KotlinLogo from "../../Assets/Images/kotlin_logo.png";
import PowerBILogo from "../../Assets/Images/powerbi_logo.png";
import ReactLogo from "../../Assets/Images/react_logo.png";
import Slider from "react-slick";

function Banner() {
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="banner-container">
        <div className="banner-section">
          <div className="banners">
            <div className="banner-left">
              <h2>
                Developing Highly <span> Skilled Tech</span> Talents
              </h2>
              <p>At Rework Academy, we are turning individuals without tech skills into world-class Tech Talents</p>
              <div className="banner-left__links">
                <Link to={"/why-rework-academy"} className="banner-left__link banner-left__link1">
                  Learn More
                </Link>
                <Link to={"/courses"} className="banner-left__link banner-left__link2">
                  Apply Now
                </Link>
              </div>
            </div>
            <div className="banner-right">
              <div className="banner-right-content">
                <div className="banner-right__circle">
                  <img src={Person} alt="Person" className="banner-right-content__img" />
                </div>
                <div className="person-writeup">
                  <div className="person-writeup__title">
                    <h4>Victor Ojogbane</h4>
                  </div>
                  <div className="skills__set">
                    <h3>
                      Full-Stack Developer
                      <p>(NODE JS)</p>
                    </h3>
                    <h4>Alumni</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banner__sub-section">
        <div className="banner__course-logos containers">
          <Slider {...settings}>
            <img src={NodeLogo} alt="Node JS" />
            <img src={ReactLogo} alt="React" />
            <img src={mongodbLogo} alt="MongoDb" />
            <img src={PythonLogo} alt="Python" />
            <img src={mySQLLogo} alt="MySQL" />
            <img src={KotlinLogo} alt="Kotlin" />
            <img src={JavaLogo} alt="Java" />
            <img src={DartLogo} alt="Dart" />
            <img src={BootstrapLogo} alt="Bootstrap" />
            <img src={PowerBILogo} alt="PowerBI" />
          </Slider>
        </div>
      </div>
    </>
  );
}

export default Banner;
