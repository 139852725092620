import React, { useState, useEffect, createContext, useCallback, useContext } from "react";
import axios from "axios";

export const CourseContext = createContext();

export const DataContext = () => useContext(CourseContext);

function CourseProvider({ children }) {
  const [errCourse, setErrCourse] = useState(false);
  const [succss, setSuccss] = useState(false);
  const [open, setOpen] = useState(false);
  const [course, setCourse] = useState([]);
  const url = process.env.REACT_APP_URL;

  const getCourses = useCallback(async () => {
    try {
      const resp = await axios.get(`${url}/courses/manager/active`);
      const courses = await resp.data;
      setCourse(courses);
      setErrCourse(false);
      if (!resp.data.length) {
        setErrCourse(true);
        setCourse([]);
      }
    } catch (error) {
      setErrCourse(true);
      setCourse([]);
    }
  }, [url]);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  const val = {
    course,
    setCourse,
    open,
    setOpen,
    succss,
    setSuccss,
    errCourse,
  };

  return (
    <>
      <CourseContext.Provider value={val}>{children}</CourseContext.Provider>
    </>
  );
}
export default CourseProvider;
