import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/Images/logo.png";
import "../Assets/Styles/Header.css";
import { IoChevronDownSharp, IoMenuOutline, IoCloseOutline } from "react-icons/io5";
import DDIcon from "../Assets/Images/dd_icon.svg";
import { DataContext } from "../Contexts/CourseContext";

function Header() {
  const [open, setOpen] = useState(false);
  const { course } = DataContext();
  const sortCourse = course.sort((a, b) => (a.name > b.name ? 1 : -1));
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <header className={`header ${open && "active"}`}>
      <nav className="navi">
        <div className="logo">
          <Link to="/" onClick={handleClose}>
            <img src={Logo} alt="Logo" className="logo-img" />
          </Link>
        </div>
        <div className={`menus ${open && "menus-active"}`}>
          <ul className="menu">
            <li>
              <Link to="/why-rework-academy" className="menu-links" onClick={handleClose}>
                Why Rework Academy
              </Link>
            </li>
            <li className="dd" id="dd">
              <Link to="/courses" className="menu-links" onClick={handleClose}>
                <span className="menu-links__sub">
                  Courses
                  <IoChevronDownSharp className="menu-links__sub-icon" />
                </span>
              </Link>
              <ul className="dd-content">
                <div className="dd-content__grid">
                  {course
                    ? sortCourse.map((co) => (
                        <Link key={co.id} to={`/courses${co.route}`} onClick={handleClose}>
                          <img src={DDIcon} alt="Icon" />
                          {co.name}
                        </Link>
                      ))
                    : null}
                </div>
              </ul>
            </li>
            <li>
              <Link to="/smarties" className="menu-links" onClick={handleClose}>
                Smarties
              </Link>
            </li>
            <li>
              <Link to="/events" className="menu-links" onClick={handleClose}>
                Events
              </Link>
            </li>
            <li>
              <Link to="/contact-us" className="menu-links" onClick={handleClose}>
                Contact
              </Link>
            </li>
            <li>
              <Link to="/courses" className="menu-links link-apply" onClick={handleClose}>
                Apply Now
              </Link>
            </li>
            <li>
              <a href="http://reworkacademy.co/portal/student" target="_blank" rel="noopener noreferrer" className="menu-links link-login">
                Login
              </a>
            </li>
          </ul>
        </div>
        <button className={`menu__show-btn ${open && "toggle-active"}`} onClick={handleToggle}>
          {open ? <IoCloseOutline className="menu__show" /> : <IoMenuOutline className="menu__show" />}
        </button>
      </nav>
    </header>
  );
}

export default Header;
