import "../Assets/Styles/Events.css";
import { BannerEvents } from "../Components/events/BannerEvents";
import EventsList from "../Components/events/EventsList";

function Events() {
  return (
    <div className="wrapper">
      <div className="webinars">
        <div className="webinars__container">
          <BannerEvents />
          <EventsList />
        </div>
      </div>
    </div>
  );
}

export default Events;
