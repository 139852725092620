import React from "react";
import { Link } from "react-router-dom";
import img from "../../Assets/Images/img5-updated.jpg";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import StandFor from "./StandFor";

function BannerWhyRework() {
  return (
    <div className="why-rework__banner">
      <div className="wr-banner__content containers">
        <h2>
          Meet The Academy <br /> Developing Tech Talents
        </h2>
        <p>
          We are turning individuals without skills into world class skilled tech talents. <br /> By providing industry focused programs for career transformation and
          growth, <br /> introducing Empirical Process Control in Training.
        </p>
        <Link to={"/courses"}>
          Apply Now
          <IoChevronForwardCircleOutline className="wr-banner__icon" />
        </Link>
      </div>
      <StandFor />
      <div className="wr-banner__content2 containers">
        <img src={img} alt="Training" />
      </div>
    </div>
  );
}

export default BannerWhyRework;
