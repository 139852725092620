import { useContext, useEffect, useState } from "react";
import BannerCourses from "../Components/courses/BannerCourses";
import "../Assets/Styles/Courses.css";
import CourseCards from "../Components/courses/CourseCards";
import { CourseContext } from "../Contexts/CourseContext";
import { useParams } from "react-router-dom";

function Courses() {
  const { course } = useContext(CourseContext);
  const [courses, setCourses] = useState([]);
  const { group } = useParams();
  const [title, setTitle] = useState("");

  function getText() {
    let filterCourses =
      course &&
      course.filter((cou) => {
        if (cou.group === group) {
          if (cou.group.includes("-")) {
            let spaceString = cou.group.replace("-", " ");
            setTitle(spaceString);
          } else {
            setTitle(cou.group);
          }
          return cou.group === group;
        }
      });
    setCourses(filterCourses);
  }

  useEffect(() => {
    getText();
  }, [course, group]);

  return (
    <div className="wrapper">
      <div className="courses">
        <BannerCourses titleCourse={title} />
        <CourseCards course={courses} />
      </div>
    </div>
  );
}

export default Courses;
