import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import BasicLayout from "./Layouts/BasicLayout";
import ErrorLayout from "./Layouts/ErrorLayout";
import ScrollToTop from "./Layouts/ScrollToTop";
import AllCourses from "./Pages/AllCourses";
import Contact from "./Pages/Contact";
import Course from "./Pages/Course";
import Courses from "./Pages/Courses";
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";
import WhyRework from "./Pages/WhyRework";
import Smarties from "./Pages/Smarties";
import EventsForm from "./Pages/EventsForm";
import Events from "./Pages/Events";
import EventsDetails from "./Pages/EventsDetails";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

function App() {
  return (
    <Router basename="/">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<BasicLayout />}>
          <Route index element={<Home />} />
          <Route path="why-rework-academy" element={<WhyRework />} />
          <Route path="courses" element={<AllCourses />} />
          <Route path="smarties" element={<Smarties />} />
          <Route path="events" element={<Events />} />
          <Route path="events/:id" element={<EventsDetails />} />
          <Route path="events/register/:id" element={<EventsForm />} />
          <Route path="course/:group" element={<Courses />} />
          <Route path="courses/:route" element={<Course />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
        </Route>
        <Route path="/" element={<ErrorLayout />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
