import React from "react";
import { Link } from "react-router-dom";
import GetSkilled from "../../Assets/Images/Coding-workshop2.png";

function GetSkills() {
  return (
    <div className="get-skilled">
      <div className="get-skilled__content containers">
        <div className="get-skilled__grid">
          <div className="get-skilled__left">
            <h5>Rework Academy</h5>
            <h2>Get Skilled. Get Hired</h2>
            <p>
              Rework Academy is instituted to give you high level tech skills to fill the technical skill shortage gap
              in the fast-growing tech industry. We focus on giving you Job Ready Skills, that gives you the opportunity
              to grow your career faster. Best of all, you pay as you learn with our Flexible monthly Payment plan. Our
              curriculums are designed to take from Novice to Advanced skills level, no experience is needed to enrol.
            </p>
            <Link to="/courses" className="get-skilled__link">
              Apply Now
            </Link>
          </div>
          <div className="get-skilled__right">
            <img src={GetSkilled} alt="Get Skilled" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetSkills;
