import React from "react";
import img1 from "../../Assets/Images/why-rework-icon1.png";
import img2 from "../../Assets/Images/why-rework-icon2.png";
import img3 from "../../Assets/Images/why-rework-icon3.png";

const StandFor = () => {
  return (
    <div className="containers values__container">
      <div className="values__content v__content-1">
        <h2>What we stand for</h2>
        <div className="values__content-grids">
          <div className="v__content-grids-sub">
            <img src={img1} alt="Industry Focused Curriculum" />
            <h3>Industry Focused Curriculum</h3>
          </div>
          <div className="v__content-grids-sub">
            <img src={img2} alt="Skilled & Experienced Trainers" />
            <h3>Practical & Project-based Learning</h3>
          </div>
          <div className="v__content-grids-sub">
            <img src={img3} alt="Empirical training Process" />
            <h3>Collaboration & Mentorship </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandFor;
