import React from "react";

export const SmartiesBanner = () => {
  return (
    <section className="smarties--banner">
      <div className="smartiess--banner__sub">
        <div className="smarties--banner__text">
          <h3>Rework Smarties</h3>
          <h1>Developing Kids to Become Creative and Innovative Minds</h1>
          <p>A Rework at School Program</p>
        </div>
      </div>
    </section>
  );
};
