export const GetInTouch = () => {
  return (
    <section className="smarties--section3">
      <div className="smarties--section3__sub">
        <div className="smarties--section3__text">
          <h2>Get In Touch</h2>
          <p>
            <strong>Location: </strong>
            House 5, block C, Aknaton estate, Da Akun <br /> Adawel street, off Dunamis road, by Old Federal Secretariat, Durumi, Area 1, Abuja
          </p>
          <p>
            <strong>Telephone: </strong>
            +234-703-561-6891
          </p>
          <p>
            <strong>Email: </strong>
            <a href="mailto:academy@reworktechnologies.com">academy@reworktechnologies.com</a>
          </p>
          <p>
            <strong>Working Hours: </strong>
            Monday - Friday: 9am – 5pm
          </p>
          <a className="smarties--section3__link" href="/contact-us">
            Contact Us
          </a>
        </div>
      </div>
    </section>
  );
};
