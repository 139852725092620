export const EmpowerLeaners = () => {
  return (
    <section className="smarties--section1">
      <div className="smarties--section1__sub">
        <div className="smarties--section1__sub1">
          <div className="smarties--sub1__sub">
            <h4>Rework Smarties</h4>
            <h2>EMPOWERING LEARNERS WITH PRACTICAL TECH SKILLS</h2>
            <p>
              We are delighted to introduce our Rework at school program, a fully practical based Tech Skill program for all learners at School. Rework at school program,
              is focused on practical ICT training for primary and secondary school students. We integrate our ICT curriculum into your school curriculum and carefully
              develop the ICT skills of your learners through practical training, meanwhile preparing them for the fourth industrial revolution and post pandemic era.
            </p>
          </div>
        </div>
        <div className="smarties--section1__sub2"></div>
      </div>
    </section>
  );
};
