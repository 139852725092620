import React from "react";
import CodingImg from "../../Assets/Images/tag.png";
import ChartImg from "../../Assets/Images/chart.png";
import DataScience from "../../Assets/Images/data-science.png";
import { Link } from "react-router-dom";

function GetTrained() {
  return (
    <div className="get-trained">
      <div className="get-trained__content">
        <div className="sub-header">
          <h2 className="sub-header__title">Get Trained with us and Become</h2>
        </div>
        <div className="get-trained__grid">
          <div className="get-trained__left">
            <div className="gt__left-container">
              <div className="gt-left__slider-dots">
                <div className="gt-slides__dot gt-slides__dot-green"></div>
                <div className="gt-slides__dot gt-slides__dot-yellow"></div>
                <div className="gt-slides__dot gt-slides__dot-red"></div>
              </div>
              <div className="gt-slides__container">
                <div className="gt-left__slides">
                  <div className="gt-left__slides-content">
                    <p>Full Stack Developer</p>
                    <img src={CodingImg} alt="Full Stack Web Developer" />
                  </div>
                </div>
                <div className="gt-left__slides">
                  <div className="gt-left__slides-content">
                    <p>Data Analyst</p>
                    <img src={ChartImg} alt="Full Stack Web Developer" />
                  </div>
                </div>
                <div className="gt-left__slides">
                  <div className="gt-left__slides-content">
                    <p>Data Scientist</p>
                    <img src={DataScience} alt="Full Stack Web Developer" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="get-trained__right">
            <div className="get-trained__right-content">
              <div className="gt__right-header">
                <div className="gt__right-txt">
                  <h3>10%</h3>
                  <p>Top Developers trained</p>
                </div>
                <div className="gt__right-txt">
                  <h3>90%</h3>
                  <p>Hiring rate for Rework Academy Graduates</p>
                </div>
              </div>
              <div className="gt__right-para">
                <p>We are turning individuals without skills into world-class skilled tech talents.</p>
              </div>
              <div className="gt__right-links">
                <Link to={"/courses"} className="gt__right-link">
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetTrained;
