import React from "react";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import SubFooter from "../../Layouts/SubFooter";

function ApplyCourse({ title }) {
  return (
    <SubFooter title={`Learn the most in-demand ${title} skills, Build real Projects with real developers`}>
      <div className="sub-footer__links">
        <a href="https://apply.reworkacademy.co/" className="link__btn link__btn-bg">
          Apply Now
          <IoChevronForwardCircleOutline className="link__btn-icon" />
        </a>
      </div>
    </SubFooter>
  );
}

export default ApplyCourse;
