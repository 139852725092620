import { useContext } from "react";
import { Link } from "react-router-dom";
import { CourseContext } from "../../Contexts/CourseContext";

function CourseCards({ course }) {
  const { errCourse } = useContext(CourseContext);
  return (
    <>
      <div className="coursecard__container">
        <div className="containers">
          {course.length !== 0 && (
            <div className="coursecard__content">
              {course.map((co) => {
                return (
                  <Link to={`/courses${co.route}`} className="coursecard__link" key={co.id}>
                    <div className="coursecard">
                      <div className="coursecard__img">
                        <img src={co.image} crossOrigin="anonymous" alt="Course" />
                      </div>
                      <div className="coursecard__descrp">
                        <h3>{co.name}</h3>
                      </div>
                      <div className="coursecard__apply">
                        <span>Apply Now</span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          )}
          {errCourse && <div className="no-courses">No Courses Available</div>}
        </div>
      </div>
    </>
  );
}

export default CourseCards;
