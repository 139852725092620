import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { EventsSuccess } from "../Components/events/EventsSuccess";

function EventsForm() {
  const { id } = useParams();
  const url = process.env.REACT_APP_URL;
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [regSuccess, setRegSuccess] = useState(false);
  const [regMsg, setRegMsg] = useState("");
  const hearAboutUs = ["Facebook", "Instagram", "Twitter(X)", "Word of mouth", "Friends & Family", "Other"];
  const webData = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    occupation: "",
    hear_about_us: "",
    webinar_id: id,
  };
  const [event, setEvent] = useState(webData);
  const handleInput = (e) => {
    const { value, name } = e.target;
    setEvent((data) => ({ ...data, [name]: value }));
  };
  const saveData = [...Object.values(event)].every(Boolean);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!saveData) {
      setErr(true);
      return;
    }
    try {
      setLoading(true);
      const resp = await axios.post(`${url}/webinar/registration`, event);
      console.log(resp);
      if (resp.data.type === "SUCCESS") {
        setRegSuccess(true);
        setEvent((prev) => (prev = webData));
        setErr(false);
        setLoading(false);
      }
      if (resp.data.type !== "SUCCESS") {
        setRegMsg("Registration not Successful, please try again");
        setLoading(false);
      }
    } catch {
      setRegMsg("Registration not Successful, please try again");
      setLoading(false);
    }
  };

  return (
    <div className="wrapper">
      <div className="webinar-form-container">
        <div className="wf__container containers">
          <form action="" className="webinar__form" onSubmit={handleSubmit}>
            <div className="wb__form-header">
              <h2>Register</h2>
              <p>Fill the form below to register for the event</p>
            </div>
            {regMsg !== "" && <div className="wb_errmsg">{regMsg}</div>}
            <div className="wb__form-group-sub">
              <div className="wb__form-group">
                <label htmlFor="fname">First name</label>
                <input
                  type="text"
                  name="first_name"
                  value={event.first_name}
                  onChange={handleInput}
                  className={err && event.first_name === "" ? "wbf-err" : ""}
                />
              </div>
              <div className="wb__form-group">
                <label htmlFor="last_name">Last name</label>
                <input type="text" name="last_name" value={event.last_name} onChange={handleInput} className={err && event.last_name === "" ? "wbf-err" : ""} />
              </div>
            </div>
            <div className="wb__form-group">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" value={event.email} onChange={handleInput} className={err && event.email === "" ? "wbf-err" : ""} />
            </div>
            <div className="wb__form-group">
              <label htmlFor="phone">Phone</label>
              <input type="text" name="phone" value={event.phone} onChange={handleInput} className={err && event.phone === "" ? "wbf-err" : ""} />
            </div>
            <div className="wb__form-group">
              <label htmlFor="occupation">Occupation</label>
              <input
                type="text"
                name="occupation"
                value={event.occupation}
                onChange={handleInput}
                className={err && event.occupation === "" ? "wbf-err" : ""}
              />
            </div>
            <div className="wb__form-group-radio">
              <label htmlFor="hear_about_us" className={err && event.hear_about_us === "" ? "wbf__err" : ""}>
                How did you hear about us?
              </label>
              {hearAboutUs.map((res, i) => (
                <div key={i}>
                  <input type="radio" name="hear_about_us" value={res} id="hear_about_us" onChange={handleInput} />
                  <label htmlFor="hear_about_us">{res}</label>
                </div>
              ))}
            </div>
            <div className="wf__group-btn">
              <button>{loading ? "Please wait..." : "Submit"}</button>
            </div>
          </form>
        </div>
        {regSuccess && <EventsSuccess setRegSuccess={setRegSuccess} />}
      </div>
    </div>
  );
}

export default EventsForm;
