import React from "react";
import ErrImg from "../Assets/Images/404-Error-Page2.png";
import "../Assets/Styles/NotFound.css";

function NotFound() {
  return (
    <div className="wrapper">
      <div className="errors">
        <div className="containerse err__content">
          <img src={ErrImg} alt="Error" />
          <h2>No Results Found</h2>
          <p>The page you requested could not be found. Try refining your search, or use the navigation above to locate the post.</p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
