import Testimonial1 from "../../Assets/Images/Emmanuel-Omolewu-updated.png";
import Testimonial2 from "../../Assets/Images/oji-innocent-updated.jpg";
import Testimonial3 from "../../Assets/Images/Bright-Abu-updated.png";

function Testimonials() {
  const testimonial = [
    {
      id: 1,
      name: "Emmanuel Omolewu",
      title: "Full Stack Developer",
      descrp:
        "Rework Academy is, the best coding Bootcamp, without any iota of doubts. The rigorous curriculum, in depth projects, the personal mentorship, and high quality instructors really prepare students to go from beginner to competitively employable in a few months.",
      img: Testimonial1,
    },
    {
      id: 2,
      name: "Oji Innocent",
      title: "Full Stack Developer",
      descrp:
        "The expertise, technologies and flexibility with which Rework Technology use to groom juveniles and mostly computer newbies into industry standard software developers and experts is next to none. I am a product of their excellent job. Because of them I have grown to be more passionate about Web applications and the various technologies. Thank you, Rework!",
      img: Testimonial2,
    },
    {
      id: 3,
      name: "Bright Abu",
      title: "Full Stack Developer",
      descrp:
        "Getting to know about Rework's software engineering bootcamp changed my life for the better. I enrolled for the program without any idea what coding was about, along with the feeling of inadequacy due largely to my lack of educational  in computer science and core programming. But the guys at Rework are awesome, offering support and listening ears every step of the way.",
      img: Testimonial3,
    },
  ];

  return (
    <div className="testimonials">
      <div className="testimonials__container containers">
        <div className="t__content-header">
          <h4 className="testimonials__header">Testimonials</h4>
          <h2 className="testimonials__header2">Hear from some of our graduates</h2>
        </div>
        <div className="testimonials__slides">
          {testimonial.map((testi) => {
            return (
              <div className="testimonials__slide" key={testi.id}>
                <p className="t__slide-text">"{testi.descrp}"</p>
                <div className="t__slide-descrp">
                  <img src={testi.img} alt="Person" />
                  <div className="t__slide-title">
                    <h3>{testi.name}</h3>
                    <p>{testi.title}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
