import React from "react";
import OptionTag from "../../Assets/Images/option-tag.png";
import { IoChevronForwardCircleOutline, IoLocationSharp, IoTimeOutline, IoCardSharp, IoCashOutline, IoHourglassOutline } from "react-icons/io5";

function CourseClassOption({ course, schedule, option, location, fee, monthly_fee }) {
  return (
    <div className="co__section">
      <div className="co__section-box">
        <div className="co__box-header">
          <h4>
            {course.duration} Full-time {option}
          </h4>
          <img src={OptionTag} alt="Class Option Tag" />
        </div>
        <div className="co__box-sub">
          <span>{course.name}</span>
        </div>
        <div className="co__box-grid">
          <p>
            <IoLocationSharp className="co__box-icon" />
            <span>Location: {location}</span>
          </p>
          <p>
            <IoHourglassOutline className="co__box-icon" />
            <span>Duration: {course.duration}</span>
          </p>
          <p>
            <IoTimeOutline className="co__box-icon" />
            <span>{schedule}</span>
          </p>
          <p>
            <IoCardSharp className="co__box-icon" />
            <span className="co__box-fee">₦{fee} (total payable fee)</span>
          </p>
          <p>
            <IoCashOutline className="co__box-icon" />
            <span className="co__box-fee">₦{monthly_fee} (monthly payment)</span>
          </p>
        </div>
        <div className="co__box-link">
          <a href="https://apply.reworkacademy.co/" className="link__btn link__btn-outline-darkblue">
            Apply Now
            <IoChevronForwardCircleOutline className="link__btn-icon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default CourseClassOption;
