import React from "react";
import "../Assets/Styles/Footer.css";

function SubFooter({ title, children }) {
  return (
    <div className="sub-footer">
      <div className="sub-footer__container containers">
        <div className="sub-footer__content">
          <h3>{title}</h3>
          <div className="sub-footer__links">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default SubFooter;
