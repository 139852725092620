import React from "react";
import missionImg from "../../Assets/Images/mission.png";

function OurMission() {
  return (
    <div className="our-mission">
      <div className="our-mission__container containers">
        <div className="mission_content">
          <h3 className="mission__header">Our Mission</h3>
          <p>To empower the fast-growing youth population with world class talents to fill the skill gap in Africa and reduce unemployment in the tech industry.</p>
        </div>
        <div className="misson__img">
          <img src={missionImg} alt="Our Mission" />
        </div>
      </div>
    </div>
  );
}

export default OurMission;
